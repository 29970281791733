import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Squash } from "hamburger-react";
import useClickOutside from "../../hook/useClickOutside";
import { useGetCategoriesQuery } from "../../redux/api/categoryApi";
import {useGetMeQuery} from '../../redux/api/userApi'

const Header = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [category, setCategory] = useState([]);
  const [showSubbar, setShowSubbar] = useState({
    dropdownNumber: null,
    show: false,
    hidden: true,
  });
  const { data: categories } = useGetCategoriesQuery();

  const { loading, user, isAuthenticated } = useSelector((state) => state.auth);
  const {data} = useGetMeQuery();

  const ref = useClickOutside(() => setShowSidebar(false));

  const sidebarItems = [
    {
      name: "Anasayfa",
      url: "/mendels/fisekhane",
    },
    {
      name: "Ürünler",
      url: "/mendels/fisekhane/urunler",
      dropdown: false,
    },
    {
      name: "Kategoriler",
      dropdown: true,
      dropdownNumber: 2,
      dropdownItems: category,
    },
    {
      name: "Medels.com.tr",
      url: "https://www.mendels.com.tr",
    },
  ];

  useEffect(() => {
    if (categories) {
      categories?.categories?.map((cate) =>
        setCategory((oldArray) => [
          ...oldArray,
          { name: cate?.name, url: `/mendels/fisekhane/kategori/${cate?.slug}` },
        ])
      );
    }
  }, [categories]);

  return (
    <div className="mb-18">
      <div className="border-b h-16 shadow-md w-full fixed z-20 top-0 left-0 bg-white">
        <div className="flex justify-center items-center h-16">
          <div className="flex items-end">
            <a href="/mendels/fisekhane">
              <img src="/images/logo.png" alt="" className="h-14 w-32" />
            </a>
          </div>
        </div>
        <div className="flex justify-end items-center max-sm:pe-5 -mt-14 pe-10">
          <Squash onToggle={() => setShowSidebar(!showSidebar)} />
        </div>
        {/* SIDEBAR */}
        <div className={`flex justify-end `}>
          <div
            className={`bg-[#FCF8ED] shadow-2xl mt-2 ${
              !showSidebar ? "w-0 h-0" : "w-96 h-screen "
            } transition-all duration-500 `}
          >
            <div className="flex justify-center py-5 border-b">
              <a href="/mendels/fisekhane">
                <img src="/images/logo.png" alt="logo" className="h-32" />
              </a>
            </div>
            <div className=" mt-5 select-none">
              {sidebarItems?.map((item) =>
                item?.dropdown ? (
                  <ul>
                    <li
                      className="flex justify-between cursor-pointer px-4"
                      onClick={() =>
                        setShowSubbar({
                          dropdownNumber: item?.dropdownNumber,
                          show: !showSubbar.show,
                          hidden: !showSubbar.hidden,
                        })
                      }
                    >
                      <a
                        className={`text-4xl transition-colors ${
                          showSubbar?.dropdownNumber === item?.dropdownNumber &&
                          !showSubbar?.hidden &&
                          showSubbar?.show &&
                          "text-orange-500"
                        }`}
                      >
                        {item?.name}
                      </a>
                      <i
                        class={`ri-arrow-right-line text-2xl transition-all transform text-black ${
                          showSubbar?.dropdownNumber === item?.dropdownNumber &&
                          !showSubbar?.hidden &&
                          showSubbar?.show &&
                          "rotate-90 text-orange-500"
                        }`}
                      />
                    </li>

                    <ul
                      className={`${
                        showSubbar?.show &&
                        showSubbar?.dropdownNumber === item?.dropdownNumber &&
                        !showSubbar?.hidden
                          ? "h-full"
                          : "h-0"
                      } transition-all`}
                    >
                      {item?.dropdownItems?.map((dItem) => (
                        <li
                          className={`flex justify-between cursor-pointer py-2 px-6 text-2xl transition-all ${
                            showSubbar?.show &&
                            showSubbar?.dropdownNumber ===
                              item?.dropdownNumber &&
                            !showSubbar?.hidden
                              ? ""
                              : "hidden"
                          }`}
                        >
                          <a
                            href={dItem?.url}
                            className="hover:text-orange-500"
                          >
                            {dItem?.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </ul>
                ) : (
                  <ul>
                    <li className="flex justify-between cursor-pointer px-4 mt-3">
                      <a href={item?.url} className="text-4xl">
                        {item?.name}
                      </a>
                    </li>
                  </ul>
                )
              )}
              {user?.data?.role === "admin" && isAuthenticated && (
                <ul>
                  <li className="flex justify-between cursor-pointer px-4 mt-3">
                    <a href="/mendels/fisekhane/admin/urunler" className="text-4xl">
                      Admin Sayfası
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
