import React from "react";
import { useGetCategoriesQuery } from "../../redux/api/categoryApi";

const Footer = () => {
  const {data: categories} = useGetCategoriesQuery();
  return (
    <div className="bg-black text-white md:flex justify-center py-10 items-center mt-10 overflow-hidden">
      <div className="grid max-md:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
        <div className="flex justify-center items-center w-full">
          <a href="">
            <img src="/images/logo.png" alt="logo" className="max-md:h-32 h-48 w-full" />
          </a>
        </div>
        <div className="max-md:ps-5">
          <h1 className="text-orange-500 text-4xl cormorant-title">
            Kullanışlı Linkler
          </h1>
          <div className="flex flex-col ">
            <a href="/" className="hover:text-orange-500 transition-all text-lg text-white">
              Anasayfa
            </a>
            <a href="/urunler" className="hover:text-orange-500 transition-all text-lg text-white">
              Ürünler
            </a>
          </div>
        </div>

        <div className="max-md:ps-5">
          <h1 className="text-orange-500 text-4xl cormorant-title">
            Kategoriler
          </h1>
          <div className="flex flex-col ">
           {categories?.categories?.map((cate) => (
             <a href={`/kategori/${cate?.slug}`} className="hover:text-orange-500 transition-all text-md text-white">{cate?.name}</a>
           ))}
          </div>
        </div>

        <div className="max-md:ps-5">
          <h1 className="text-orange-500 text-4xl cormorant-title">İletişim</h1>
          <div className="flex flex-col gap-4">
            <a
              href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwj_6Mj7qO2JAxV6X_EDHRfSId4Q4kB6BAhIEAM&url=%2Fmaps%2Fplace%2F%2Fdata%3D!4m2!3m1!1s0x14cabbd90750d57f%3A0x3fb944a51426e947%3Fsa%3DX%26ved%3D1t%3A8290%26ictx%3D111&usg=AOvVaw2yaP6XOwMkUq-kvMZIEmLG&opi=89978449"
              className="text-white hover:text-orange-500 transition-all"
            >
              Adres: Kazlıçeşme, 34020 Zeytinburnu/İstanbul
            </a>
            <a href="tel:05422454530" className="text-white hover:text-orange-500 transition-all">
              Telefon: 0542 245 45 30
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
