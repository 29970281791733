import "./App.css";
import Login from "./components/auth/Login";
import Header from "./components/layouts/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/layouts/Footer";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import GetAllProducts from "./components/admin/products/GetAllProducts";
import GetAllCategories from "./components/admin/categories/GetAllCategories";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateProduct from "./components/admin/products/CreateProduct";
import EditProduct from "./components/admin/products/EditProduct";
import CreateCategory from "./components/admin/categories/CreateCategory";
import EditCategory from "./components/admin/categories/EditCategory";
import GetAllUsers from "./components/admin/users/GetAllUsers";
import CreateUser from "./components/admin/users/CreateUser";
import UpdateUser from "./components/admin/users/UpdateUser";
import Qr from "./components/admin/qr/Qr";
import ProductDetails from "./pages/ProductDetails";
import Products from "./pages/Products";
import Categories from "./pages/Categories";

function App() {
  return (
    <Router>
      <Header />
      <ToastContainer position="top-right" />
      <Routes>
        <Route path="/mendels/fisekhane" element={<Home />} />
        <Route path="/mendels/fisekhane/urunler" element={<Products />} />
        <Route path="/mendels/fisekhane/urun/:category/:id" element={<ProductDetails />} />
        <Route path="/mendels/fisekhane/kategori/:category" element={<Categories />} />
        <Route path="/giris" element={<Login />} />
        <Route path="/mendels/fisekhane/admin/urunler" element={<ProtectedRoute><GetAllProducts /></ProtectedRoute>} />
        <Route path="/mendels/fisekhane/admin/urun/:id" element={<ProtectedRoute><EditProduct /></ProtectedRoute>} />
        <Route path="/mendels/fisekhane/admin/urun-ekle" element={<ProtectedRoute><CreateProduct /></ProtectedRoute>} />
        <Route path="/mendels/fisekhane/admin/kategoriler" element={<ProtectedRoute><GetAllCategories /></ProtectedRoute>} />
        <Route path="/mendels/fisekhane/admin/kategori-ekle" element={<ProtectedRoute><CreateCategory /></ProtectedRoute>} />
        <Route path="/mendels/fisekhane/admin/kategori/:id" element={<ProtectedRoute><EditCategory /></ProtectedRoute>} />
        <Route path="/mendels/fisekhane/admin/kullanicilar" element={<ProtectedRoute><GetAllUsers /></ProtectedRoute>} />
        <Route path="/mendels/fisekhane/admin/kullanici/:id" element={<ProtectedRoute><UpdateUser /></ProtectedRoute>} />
        <Route path="/mendels/fisekhane/admin/kullanici-ekle" element={<ProtectedRoute><CreateUser /></ProtectedRoute>} />
        <Route path="/mendels/fisekhane/admin/qr" element={<ProtectedRoute><Qr /></ProtectedRoute>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
