import React from "react";

const CategoryItem = ({ category }) => {
  const onClick = (slug) => {
    window.location.href = `/mendels/fisekhane/kategori/${slug}`
  }
  return (
    <div className="relative group cursor-pointer overflow-hidden rounded-md max-h-[400px]" onClick={() => onClick(category?.slug)}>
      <img
        src={category?.image?.url}
        className=" rounded-md transform group-hover:rotate-3 group-hover:scale-110 transition-all duration-500 w-full h-full"
        alt=""
      />
      <div className="absolute opacity-100 flex justify-center items-center bg-black bg-opacity-15 inset-0 rounded-md">
        <p className="bg-black bg-opacity-20 p-5 rounded-full text-white font-bold text-xl">
          {category?.name}
        </p>
      </div>
    </div>
  );
};

export default CategoryItem;
