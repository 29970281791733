import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCreateUserMutation } from "../../../redux/api/userApi";
import MetaData from "../../layouts/MetaData";

const CreateUser = () => {
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });
  const { firstname, lastname, email, password } = data;

  const [createUser, { error, isSuccess, isLoading }] = useCreateUserMutation();

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await createUser({ firstname, lastname, email, password });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      toast.success("Kayıt oluşturuldu.");
    }
  }, [error, isSuccess]);

  return (
    <div className="mx-3 bg-white p-3 rounded-md">
      <MetaData text={"Kullanıcı Oluştur"} />

      <div className="mb-5">
        <h1 className="text-4xl font-bold cormorant-title">Kullanıcı Ekle</h1>
      </div>
      <form method="post" onSubmit={onSubmit}>
        <div className="flex flex-col mb-6">
          <label
            htmlFor="firstname"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ad
          </label>
          <input
            type="text"
            name="firstname"
            placeholder="Adınızı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="lastname"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Soyad
          </label>
          <input
            type="text"
            name="lastname"
            placeholder="Soyadınızı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="email"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            E-Posta
          </label>
          <input
            type="email"
            name="email"
            placeholder="E-Postanızı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="password"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Parola
          </label>
          <input
            type="password"
            name="password"
            placeholder="Parolanızı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-orange-500 text-white py-3 px-10 rounded-lg"
            disabled={isLoading}
          >
            {isLoading ? "Ekleniyor..." : "Oluştur"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateUser;
