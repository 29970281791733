import React, { useEffect } from "react";
import TableList from "../../layouts/TableList";
import { Button, Popconfirm, Space } from "antd";
import { toast } from "react-toastify";
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from "../../../redux/api/userApi";
import MetaData from "../../layouts/MetaData";

const GetAllUsers = () => {
  const { data: users } = useGetUsersQuery();
  const [deleteProduct, { error, isSuccess }] = useDeleteUserMutation();
  const columns = [
    {
      title: "Adı",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "Soyadı",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "E-Posta",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "İşlemler",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a href={`/mendels/fisekhane/admin/kullanici/${record?._id}`}>
            <Button color="primary">Güncelle</Button>
          </a>
          <Popconfirm
            title="Kişi Sil"
            description="Bu kişiyi silmek istediğine emin misin?"
            onConfirm={() => deleteProduct(record?._id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button danger>Sil</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      toast.success("Ürün Başarıyla Silindi");
    }
  }, [error, isSuccess]);

  return (
    <div className="bg-white mx-3 rounded-lg">
      <MetaData text={"Kullanıcılar"} />

      <h1 className="ps-3 mb-4 text-4xl font-bold cormorant-title pt-5">ÜYELER</h1>
      <TableList columns={columns} data={users?.users} />
    </div>
  );
};

export default GetAllUsers;
