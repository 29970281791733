import React, { useEffect } from "react";
import TableList from "../../layouts/TableList";
import {
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
} from "../../../redux/api/categoryApi";
import { Button, Popconfirm, Space } from "antd";
import { toast } from "react-toastify";
import MetaData from "../../layouts/MetaData";

const GetAllCategories = () => {
  const { data: categories } = useGetCategoriesQuery();
  const [deleteCategory, { error, isSuccess }] = useDeleteCategoryMutation();

  const deleteCategoryFunc = ({ id }) => {
    deleteCategory({ id });
  };

  const columns = [
    {
      title: "Kategori Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "İşlemler",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a href={`/mendels/fisekhane/admin/kategori/${record?._id}`}>
            <Button color="primary">Güncelle</Button>
          </a>
          <Popconfirm
            title="Kategoriyi Sil"
            description="Bu Kategoriyi silmek istediğine emin misin?"
            onConfirm={() => deleteCategoryFunc({ id: record?._id })}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button danger>Sil</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      toast.success("Kategori silindi.");
    }
  }, [error, isSuccess]);
  return (
    <div className="bg-white mx-3 rounded-lg">
      <MetaData text={"Tüm Kategoriler"} />
      <h1 className="ps-3 mb-4 text-4xl font-bold cormorant-title pt-5">
        KATEGORİLER
      </h1>
      <TableList columns={columns} data={categories?.categories} />
    </div>
  );
};

export default GetAllCategories;
