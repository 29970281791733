import React from "react";
import { Tabs as Tab } from "antd";


const Tabs = ({setActiveTab, activeTab}) => {

  return <div className="flex gap-2">
    <div className={`${activeTab === "desc" && "border-s-2 border-t-2 border-e-2 border-orange-500 rounded-t-md"}`} onClick={() => setActiveTab("desc")}>
      <button className="heebo px-2 pt-2">Açıklama</button>
    </div>
    <div className={`${activeTab === "details" && "border-s-2 border-t-2 border-e-2 border-orange-500 rounded-t-md"}`} onClick={() => setActiveTab("details")}>
      <button className="heebo px-2 pt-2">Detay</button>
    </div>
  </div>
};
export default Tabs;
