import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Sidebar from '../layouts/Sidebar'
import Loader from '../layouts/Loader'

const ProtectedRoute = ({children}) => {
    const navigate = useNavigate();
    const {isAuthenticated, loading} = useSelector(state => state.auth);
    const [showSidebar, setShowSidebar] = useState(false)


    if (loading) {
        return <Loader />
    }
    if (!isAuthenticated) {
        navigate("/mendels/fisekhane");
    }

  return (
    <div className='mt-16 flex'>
        <div className={`border-r-2 h-[95vh] py-5 ${showSidebar ? "lg:w-52 xl:w-1/6" : "w-20"} transition-all`}>
            <Sidebar setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
        </div>
        <div className="bg-indigo-50 py-5 max-md:w-5/6 md:w-full">
            {children}
        </div>
    </div>
  )
}

export default ProtectedRoute