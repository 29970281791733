import React from "react";
import { Button, Popconfirm, Space, Table, Tag } from "antd";


const TableList = ({columns, data}) => (
  <div className="px-3 max-sm:w-72">
    <Table columns={columns} dataSource={data} scroll={{x: 100}}  />
  </div>
);
export default TableList;
