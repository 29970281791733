import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../components/layouts/Breadcrumb";
import Tabs from "../components/layouts/Tabs";
import { useGetProductDetailsQuery } from "../redux/api/productApi";
import { useParams } from "react-router-dom";
import Loader from "../components/layouts/Loader";
import MetaData from "../components/layouts/MetaData";

const ProductDetails = () => {
  const imageRef = useRef(null);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [selectedImage, setSelectedImage] = useState("");
  const [activeTab, setActiveTab] = useState("desc");
  const [path, setPath] = useState([]);

  const { id } = useParams();
  const { data: prdct, isLoading } = useGetProductDetailsQuery({ id });
  const product = prdct?.product;

  const onChangeSelectedImage = ({ url }) => setSelectedImage(url);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        setImageWidth(entries[0].contentRect.width);
        setImageHeight(entries[0].contentRect.height);
      }
    });

    // imageRef mevcut olduğunda gözlemleyin
    if (imageRef.current) {
      resizeObserver.observe(imageRef.current);
    }

    if (product) {
      setSelectedImage(product?.images[0]?.url);
      setPath([
        {
          name: "Anasayfa",
          url: "/mendels/fisekhane",
        },
        {
          name: `${product?.category?.name}`,
          url: `/mendels/fisekhane/kategori/${product?.category?.slug}`,
        },
        {
          name: `${product?.name}`,
          url: `/mendels/fisekhane/urun/${product?.category?.slug}/${product?._id}`,
        },
      ]);
    }
    // Cleanup: Observer'ı bileşen kaldırıldığında durdurun
    return () => {
      if (imageRef.current) {
        resizeObserver.unobserve(imageRef.current);
      }
    };
  }, [product]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="mt-10 min-h-screen">
      <MetaData text={product?.name ? product?.name : "Ürün detayı" } />
      {/* BREADCRUMB-WRAPPER */}
      <div className="max-lg:px-10 pt-10 lg:px-32 2xl:px-64 max-md:relative z-10">
        <Breadcrumb paths={path} />
      </div>

      <div className="flex max-md:flex-col max-md:px-10 md:px-16 lg:px-32 xl:px-64 mt-10 gap-5 max-sm:justify-center max-sm:items-center">
        {/* IMAGES-WRAPPER */}
        <div className="flex max-md:flex-col gap-1 w-full md:w-4/5">
          {/* FOR-COMPUTER-IMAGES */}
          <div
            className={`flex flex-col gap-2 overflow-auto max-md:hidden`}
            style={{ maxHeight: `${imageHeight}px` }}
          >
            {product?.images?.map((img) => (
              <img
                src={img?.url}
                alt="img"
                className={`h-20 w-36 cursor-pointer rounded-md ${
                  img?.url === selectedImage && "border-2 border-orange-500"
                }`}
                onClick={() => onChangeSelectedImage({ url: img?.url })}
              />
            ))}
          </div>
          {/* SELECTED-IMAGES */}
          <div className="">
            <img
              src={selectedImage}
              alt="img"
              ref={imageRef}
              className="max-h-[700px] h-full"
            />
          </div>
          {/* FOR-MOBILE-IMAGES */}
          <div
            className={`flex md:hidden gap-2 w-full overflow-auto`}
            style={{ maxWidth: `${imageWidth}px` }}
          >
            {product?.images?.map((img) => (
              <img
                src={img?.url}
                alt="img"
                className={`h-20 w-16 rounded-md ${
                  img?.url === selectedImage && "border-2 border-orange-500"
                }`}
                onClick={() => onChangeSelectedImage({ url: img?.url })}
              />
            ))}
          </div>
        </div>
        {/* CONTENT-WRAPPER */}
        <div className="p-4 w-full">
          <h1 className="max-sm:text-4xl sm:text-4xl font-bold cormorant-title mb-5">
            {product?.name}
          </h1>
          <p className="">{product?.subtitle}</p>
          <div className="flex justify-between items-center">
            <p className="mt-5">
              <a href={`/kategori/${product?.category?.slug}`}>
                KATEGORİ:{" "}
                <span className="font-bold text-orange-500">
                  {product?.category?.name}
                </span>
              </a>
            </p>
            <p className="mt-5 text-2xl">
              Fiyat:{" "}
              <span className="font-bold text-orange-500">
                {product?.price}₺
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center mt-20 max-lg:px-10 lg:px-32 2xl:px-64">
        <div className="">
          <Tabs setActiveTab={setActiveTab} activeTab={activeTab} />
        </div>

        <div className="h-[1px] w-full bg-orange-500 mb-10" />

        <div className="">
          {activeTab === "desc" ? (
            <p className="text-center italic">{product?.desc}</p>
          ) : (
            <p className="text-center italic"></p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
