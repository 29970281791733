import React, { useState, useEffect, useRef } from "react";
import { delay, motion, useAnimation, useInView } from "framer-motion";
const Reveal = ({ children }) => {
  const ref = useRef(null);
  const isInViews = useInView(ref, { once: true });
  const mainControl = useAnimation();

  useEffect(() => {
    if (isInViews) {
      mainControl.start("visible");
    }
  }, [isInViews]);
  return (
    <div ref={ref}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControl}
        transition={{ duraiton: 0.5, delay: 0.25 }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default Reveal;
