import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Web için localStorage kullanımı
import userSlice from "./features/userSlice";
import { userApi } from "./api/userApi";
import { authApi } from "./api/authApi";
import { categoryApi } from "./api/categoryApi";
import { productApi } from "./api/productApi";
import { qrApi } from "./api/qrApi";

// redux-persist yapılandırması
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"], // Sadece 'auth' reducer'ını kalıcı yapar
};

// `userSlice` reducer'ını persist ile sarın
const persistedReducer = persistReducer(persistConfig, userSlice);

// Redux store'u oluşturun
export const store = configureStore({
  reducer: {
    auth: persistedReducer, // Persist edilmiş reducer burada kullanılıyor
    [userApi.reducerPath]: userApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [qrApi.reducerPath]: qrApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // redux-persist verileri için serializable check devre dışı bırakıldı
    }).concat([
      userApi.middleware,
      authApi.middleware,
      categoryApi.middleware,
      productApi.middleware,
      qrApi.middleware,
    ]),
});

// Persistor'u oluşturun
export const persistor = persistStore(store);
