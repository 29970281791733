import React, { useEffect } from "react";
import {useNavigate} from "react-router-dom";
import ProductItem from "./ProductItem";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useGetProductsQuery } from "../../redux/api/productApi";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Loader from "../layouts/Loader";
gsap.registerPlugin(ScrollTrigger);

const Products = ({title, subtitle, text, products}) => {
  const navigate = useNavigate();
  let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const onClick = () => {
    navigate("/mendels/fisekhane/urunler");
    window.scrollTo(0,0)
  }

  useEffect(() => {
    gsap.fromTo(
      ".fade-left",
      {
        opacity: 0,
        x: -200,
      },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        scrollTrigger: {
          trigger: ".fade-left",
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      ".fade-right",
      {
        opacity: 0,
        x: 200,
      },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        scrollTrigger: {
          trigger: ".fade-right",
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);
  return (
    <>
      <div className="flex flex-col w-full items-center my-10">
        <span className="cormorant-title-bold text-orange-500 fade-left">
          {subtitle}
        </span>
        <div className="relative">
          <h1 className="max-md:text-4xl text-6xl font-bold cormorant fade-right text-center">
            {title}
          </h1>
          <div className="absolute bottom-0 left-1/2 transform translate-x-1/2 translate-y-1/2">
            <p className="quentin max-md:text-4xl text-6xl fade-left">{text}</p>
          </div>
        </div>
      </div>
      <div className="overflow-hidden max-sm:px-10 px-32">
        <Slider {...settings}>
          {products?.products?.slice(0,8)?.map((product) => (
            <ProductItem product={product} />
          ))}
          <div className="cursor-pointer p-1 rounded-lg mx-2">
            <div className="flex flex-col justify-center items-center mt-5 min-h-[500px]">
              <p>Buradaki tüm ürünleri gördün</p>
              <button className="text-center w-full py-5 mt-3 bg-orange-500 text-white rounded-lg" onClick={onClick}>
                Diğer Tüm Ürünleri Görüntüle
              </button>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
};

export default Products;
