import React from "react";

const ProductItem = ({product}) => {
  const onClick = (slug, id) => {
    window.location.href = `/mendels/fisekhane/urun/${slug}/${id}`
  }
  return (
    <div className="cursor-pointer p-1 rounded-lg mx-2">
      <div className="relative group">
        <div className="max-h-[400px]  h-full w-full" >
          <img
            src={product?.images[0]?.url}
            alt={product?.name}
            className="rounded-t-lg max-h-[300px] min-h-[300px] h-full w-full"
          />
        </div>
        <div className="absolute opacity-0 group-hover:opacity-100 transition-all rounded-t-lg bg-black bg-opacity-15 flex justify-center items-center inset-0" onClick={() => onClick(product?.category?.slug, product?._id)}>
          <button className="font-bold cormorant-title text-3xl text-white" >
            ÜRÜNE GİT
          </button>
        </div>
      </div>
      <div className="flex justify-between items-center mt-5 ">
        <h1 className="text-3xl" onClick={() => onClick(product?.category?.slug, product?._id)}>{product?.name}</h1>
        <p className="text-orange-500" onClick={() => onClick(product?.category?.slug, product?._id)}>{product?.price}₺</p>
      </div>
      <div className="mt-5">
        <p className=" line-clamp-3">{product?.subtitle}</p>
      </div>
      <div className="">
        <button className="text-center w-full py-5 mt-3 bg-orange-500 text-white rounded-lg" onClick={() => onClick(product?.category?.slug, product?._id)}>Ürüne Git</button>
      </div>
    </div>
  );
};

export default ProductItem;
