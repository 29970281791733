import React from 'react'
import SliderComp from '../components/layouts/Slider'
import Products from '../components/products/Products'
import Categories from '../components/categories/Categories'
import SwipeCard from '../components/layouts/SwipeCard'
import { useGetLatestProductsQuery, useGetProductsQuery } from '../redux/api/productApi'
import Loader from '../components/layouts/Loader'
import MetaData from '../components/layouts/MetaData'

const Home = () => {
  const { data: products, isLoading } = useGetProductsQuery();
  const { data: latestProducts } = useGetLatestProductsQuery();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className='overflow-hidden'>
      <MetaData text={"Anasayfa"} />
        <div className="overflow-hidden">
         <SliderComp />
        </div>
        {/* CATEGORIES */}
        <div className="">
          <Categories />
        </div>
        
        {/* PRODUCTS */}
        <div className="bg-gradient-to-t from-[#FCF8ED] to-transparent overflow-hidden">
          <Products text={"incele"} title={"Ürünler"} subtitle={"En Yeni Trendleri Keşfedin"} products={products} />
        </div>

        {/* SWIPE-CARS */}
        <div className="bg-[#FCF8ED] -mt-10 pt-10 -mb-10 pb-10 overflow-hidden">
          <div className="overflow-hidden">
            <SwipeCard />
          </div>
        </div>

        {/* PRODUCTS */}
        <div className="bg-gradient-to-b from-[#FCF8ED] to-transparent overflow-hidden pt-10">
          <Products text={"kesfet"} title={"En Yeni Trendleri Keşfedin"} subtitle={"Tarzınıza Uygun Seçenekler"} products={latestProducts} />
        </div>
    </div>
  )
}

export default Home