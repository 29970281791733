import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userSlice } from "../features/userSlice";
import { userApi } from "./userApi";
import axios from "axios";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://qr-menu-project.onrender.com/api",
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      let token = getState().auth.token;

      // Eğer Redux'ta token yoksa, localStorage'dan kontrol et
      if (!token) {
        token = localStorage.getItem('token'); // Alternatif olarak Cookies de kullanılabilir
      }

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    }
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query(body) {
        return {
          url: "/login",
          method: "POST",
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          localStorage.setItem("token", data?.token);
          dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    logout: builder.query({
      query: () => "/logout",
    }),
  }),
});

export const { useLoginMutation, useLazyLogoutQuery } = authApi;
