import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const categoryApi = createApi({
  reducerPath: "categoryApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://qr-menu-project.onrender.com/api", credentials: "include" }),
  tagTypes: ["Category"],
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => `/categories`,
      providesTags: ["Category"],
    }),
    getCategoryDetails: builder.query({
      query: ({ id }) => `/category-details/${id}`,
      providesTags: ["Category"],
    }),
    createCategory: builder.mutation({
      query(body) {
        return {
          url: "/create/category",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Category"],
    }),
    updateCategory: builder.mutation({
      query(body) {
        return {
          url: `/category-details/${body?.id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Category"],
    }),
    deleteCategory: builder.mutation({
      query(body) {
        return {
          url: `/category-details/${body?.id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Category"],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetCategoryDetailsQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation
} = categoryApi;
