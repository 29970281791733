import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCreateUserMutation, useGetUserQuery, useUpdateUserMutation } from "../../../redux/api/userApi";
import { useParams } from "react-router-dom";
import MetaData from "../../layouts/MetaData";

const UpdateUser = () => {
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    role: ""
  });
  const { firstname, lastname, email, role } = data;

  const { id } = useParams();
  const { data: user } = useGetUserQuery(id);
  const [updateUser, { error, isSuccess, isLoading }] = useUpdateUserMutation();

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUser({ firstname, lastname, email, role, id });
    } catch (error) {
      console.log(error);
    }
  };

  console.log(role)

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      toast.success("Kayıt güncellendi.");
    }
    if (user) {
      setData({ firstname: user?.user?.firstname, lastname: user?.user?.lastname, email: user?.user?.email, role: user?.user?.role });
    }
  }, [error, isSuccess, user]);

  return (
    <div className="mx-3 bg-white p-3 rounded-md">
      <MetaData text={"Kullanıcıyı Güncelle"} />
      <div className="mb-5">
        <h1 className="text-4xl font-bold cormorant-title">Kullanıcı Güncelle</h1>
      </div>
      <form method="post" onSubmit={onSubmit}>
        <div className="flex flex-col mb-6">
          <label
            htmlFor="firstname"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ad
          </label>
          <input
            type="text"
            name="firstname"
            value={firstname}
            placeholder="Adınızı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="lastname"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Soyad
          </label>
          <input
            type="text"
            name="lastname"
            value={lastname}
            placeholder="Soyadınızı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="email"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            E-Posta
          </label>
          <input
            type="email"
            name="email"
            value={email}
            placeholder="E-Postanızı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="role"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Rol Seçiniz
          </label>
          <select
            value={`${role}`}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
            name="role"
            onChange={(e) => onChange(e)}
          >
            <option value="">Seçin</option>
            <option value="admin">admin</option>
            <option value="user">kullanıcı</option>
          </select>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-orange-500 text-white py-3 px-10 rounded-lg"
            disabled={isLoading}
          >
            {isLoading ? "Güncelleniyor..." : "Güncelle"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateUser;
