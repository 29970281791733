import React, { useEffect, useState } from "react";
import { useLoginMutation } from "../../redux/api/authApi";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Login = () => {
  const [data, setData] = useState({ email: "", password: "" });
  const [login, { error, isSuccess }] = useLoginMutation();
  const {isAuthenticated} = useSelector(state => state.auth);
  const navigate = useNavigate();

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onClick = () => {
    login(data);
  };

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      navigate("/mendels/fisekhane/admin/urunler");
      toast.success("Giriş Başarılı");
    }
    if (isAuthenticated) {
      return navigate("/mendels/fisekhane/admin/urunler");
    }
  }, [error, isSuccess, isAuthenticated]);
  return (
    <div className="flex justify-center items-center h-[90vh]">
      <div className="border-[#C04D00] border-2 shadow-lg p-5 rounded-lg w-96 max-sm:mx-5">
        <div className="">
          <img src="/images/logo.png" alt="logo" className="h-32 w-full mb-5" />
        </div>
        <h1 className="text-3xl font-bold cormorant-title mb-5 text-[#C04D00]">
          GİRİŞ YAP
        </h1>
        <div className="flex flex-col gap-2 heebo">
          <label htmlFor="email" className="cormorant-title-bold text-2xl">E-Posta:</label>
          <input
            type="email"
            name="email"
            onChange={(e) => {
              onChange(e);
            }}
            className="border border-[#C04D00] h-12 rounded-md ps-2"
            placeholder="E-Posta Adresi"
          />
        </div>
        <div className="flex flex-col heebo mt-5">
          <label htmlFor="password" className="cormorant-title-bold text-2xl">Parola:</label>
          <input
            type="password"
            name="password"
            onChange={(e) => {
              onChange(e);
            }}
            className="border border-[#C04D00] h-12 rounded-md ps-2"
            placeholder="Parola"
          />
        </div>
        <div className="mt-5 heebo">
          <button
            className="w-full py-3 bg-[#C04D00] text-white font-bold rounded-md"
            onClick={onClick}
          >
            Giriş Yap
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
