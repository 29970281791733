import React from 'react'
import {Helmet} from 'react-helmet'

const MetaData = ({text}) => {
  return (
    <Helmet>
        <title>{text} • Mendel's Menü</title>
    </Helmet>
  )
}

export default MetaData