import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const qrApi = createApi({
  reducerPath: "qrApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://qr-menu-project.onrender.com/api", credentials: "include" }),
  tagTypes: ["Qr"],
  endpoints: (builder) => ({
    getQr: builder.query({
      query: () => `/qr`,
      providesTags: ["Qr"]
    }),
    createQr: builder.mutation({
      query({id}) {
        return {
          url: "/qr",
          method: "POST",
          body: {
            id: id
          }
        };
      },
      invalidatesTags: ["Qr"],
    }),
  }),
});

export const { useGetQrQuery, useCreateQrMutation } = qrApi;
