import React, { useEffect, useState } from "react";
import {
  useGetProductDetailsQuery,
  useUpdateProductMutation,
} from "../../../redux/api/productApi";
import { useGetCategoriesQuery } from "../../../redux/api/categoryApi";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import MetaData from "../../layouts/MetaData";

const EditProduct = () => {
  const { id } = useParams();
  const { data: prdct, isLoading } = useGetProductDetailsQuery({ id });
  const product = prdct?.product;

  const [data, setData] = useState({
    name: "",
    desc: "",
    price: "",
    category: "",
    subtitle: "",
    productOfMonth: false,
  });
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewsImages] = useState([]);

  const { name, desc, price, category, subtitle, productOfMonth } = data;
  const [
    update,
    {
      error: productError,
      isSuccess: productSuccess,
      isLoading: productLoading,
    },
  ] = useUpdateProductMutation();
  const { data: categories, error, isSuccess } = useGetCategoriesQuery();
  const navigate = useNavigate();

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onChangeImages = (e) => {
    const files = Array.from(e.target.files); // Seçilen dosyaları al
    const promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file); // Dosyayı base64 formatına çevir
        reader.onload = () =>
          resolve({
            url: reader.result,
            public_id: reader.result,
            isBase64: true,
          });
      });
    });

    // Tüm resimleri base64 formatına çevir ve state'e ekle
    Promise.all(promises).then((base64Images) => {
      setPreviewsImages([...previewImages, ...base64Images]);
      setImages([...images, ...base64Images]);
    });
  };

  const removeImage = ({ public_id }) => {
    const filteredImages = previewImages?.filter(
      (image) => image?.public_id !== public_id
    );
    setPreviewsImages([...filteredImages]);
    setImages([...filteredImages]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await update({
        name,
        desc,
        price,
        category,
        images,
        subtitle,
        productOfMonth,
        id,
      });
    } catch (error) {
      console.error("Yükleme Hatası:", error);
    }
  };

  useEffect(() => {
    if (productError) {
      toast.error(productError?.data?.message);
    }
    if (productSuccess) {
      toast.success("Ürün güncellendi.");
    }

    if (product) {
      setData({
        name: product?.name,
        desc: product?.desc,
        price: product?.price,
        category: product?.category,
        subtitle: product?.subtitle,
        productOfMonth: product?.productOfMonth,
      });
      setPreviewsImages([...product?.images]);
      setImages([...product?.images]);
    }
  }, [productError, productSuccess, product]);

  if (isLoading) {
    return <div>Yükleniyor</div>;
  }

  return (
    <div className="mx-3 bg-white p-3 rounded-md">
      <MetaData text={"Ürün Güncelle"} />
      <div className="mb-5">
        <h1 className="text-4xl font-bold cormorant-title">Ürün Güncelle</h1>
      </div>
      <form method="post" onSubmit={onSubmit}>
        <div className="flex flex-col mb-6">
          <label
            htmlFor="name"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ürün Adı
          </label>
          <input
            type="text"
            name="name"
            value={name}
            placeholder="Ürün adı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="subtitle"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ürün Altbaşlığı
          </label>
          <textarea
            name="subtitle"
            placeholder="Ürün altbaşlığı girin"
            value={subtitle}
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="desc"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ürün Açıklaması
          </label>
          <textarea
            name="desc"
            placeholder="Ürün açıklaması girin"
            value={desc}
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="price"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ürün Fiyatı
          </label>
          <input
            type="number"
            name="price"
            value={price}
            placeholder="Ürün fiyatı girin"
            onChange={(e) => onChange(e)}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="images"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Ürün Resmi
          </label>
          <input
            type="file"
            multiple
            name="images"
            onChange={onChangeImages}
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>
        <div className="flex gap-2 sm:ps-5 mb-3">
          {previewImages.map((img) => (
            <div
              className="relative cursor-pointer"
              onClick={() => removeImage({ public_id: img.public_id })}
            >
              <img
                src={img?.url}
                alt="p-img"
                className="h-16 w-20 rounded-md"
              />
              <div className="absolute opacity-0 hover:opacity-100 transition-all bg-red-500 bg-opacity-15 inset-0 flex justify-center items-center rounded-md">
                <FaRegTrashAlt color="white" size={24} />
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor="category"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Kategori Seçiniz
          </label>
          <select
            defaultValue={category}
            name="category"
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
            onChange={(e) => onChange(e)}
          >
            <option value="">Seçin</option>
            {categories?.categories?.map((cate) => (
              <option value={cate._id}>{cate.name}</option>
            ))}
          </select>
        </div>

        <div className="flex gap-4 items-center mb-6">
          <label
            htmlFor="productOfMonth"
            className="text-lg font-medium text-gray-700"
          >
            Ayın Ürünü:
          </label>
          <input
            type="checkbox"
            name="productOfMonth"
            checked={productOfMonth}
            onChange={(e) =>
              setData({ ...data, productOfMonth: e.target.checked })
            }
            className="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
          />
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-orange-500 text-white py-3 px-10 rounded-lg"
            disabled={productLoading}
          >
            {productLoading ? "Güncelleniyor..." : "Güncelle"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProduct;
