import React, { useEffect } from "react";
import TableList from "../../layouts/TableList";
import { Button, Popconfirm, Space } from "antd";
import {
  useDeleteProductMutation,
  useGetProductsQuery,
} from "../../../redux/api/productApi";
import { toast } from "react-toastify";
import MetaData from "../../layouts/MetaData";

const GetAllProducts = () => {
  const { data: products } = useGetProductsQuery();
  const [deleteProduct, { error, isSuccess }] = useDeleteProductMutation();
  const columns = [
    {
      title: "Ürün Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ürün Fiyatı",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "İşlemler",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a href={`/mendels/fisekhane/urun/${record?.category?.slug}/${record?._id}`}>
            <Button color="primary">Ürüne Git</Button>
          </a>
          <a href={`/mendels/fisekhane/admin/urun/${record?._id}`}>
            <Button color="primary">Güncelle</Button>
          </a>
          <Popconfirm
            title="Ürünü Sil"
            description="Bu ürünü silmek istediğine emin misin?"
            onConfirm={() => deleteProduct(record?._id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button danger>Sil</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      toast.success("Ürün Başarıyla Silindi");
    }
  }, [error, isSuccess]);
  return (
    <div className="bg-white mx-3 rounded-lg">
      <MetaData text={"Tüm Ürünler"} />
      <h1 className="ps-3 mb-4 text-4xl font-bold cormorant-title pt-5">
        ÜRÜNLER
      </h1>
      <TableList columns={columns} data={products?.products} />
    </div>
  );
};

export default GetAllProducts;
