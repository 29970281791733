import React, { useEffect } from 'react'
import {toast} from 'react-toastify'
import { useCreateQrMutation, useGetQrQuery } from '../../../redux/api/qrApi'
import MetaData from '../../layouts/MetaData'

const Qr = () => {
  const {data: qr} = useGetQrQuery();
  const [createQr, {error, isSuccess}] = useCreateQrMutation();

  useEffect(() => {
    if (error) toast.error(error?.data?.message);
    if (isSuccess) toast.success("Yeni Qr Oluşturuldu.");
  }, [error, isSuccess]);
  return (
    <div className='flex flex-col items-center pb-10 justify-center px-3 bg-white p-2 m-3 rounded-md'>
      <MetaData text={"Qr"} />
      <div className="">
        <h1 className='text-4xl cormorant-title text-center'>Mevuct Qr</h1>
        <img src={qr?.qr[0]?.image?.url} alt="qr" className='w-64' />
      </div>
      <div className="">
        <button className='bg-orange-500 text-white heebo max-sm:w-full py-4 rounded-md px-5' onClick={() => createQr({id: qr?.qr[0]?._id})}>Yeni Qr Code Oluştur</button>
      </div>
    </div>
  )
}

export default Qr