import React, { useEffect } from "react";
import CategoryItem from "./CategoryItem";
import { useGetCategoriesQuery } from "../../redux/api/categoryApi";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Categories = () => {
  const { data: categories } = useGetCategoriesQuery();

  useEffect(() => {
    gsap.fromTo(
      ".category-fade-left",
      {
        opacity: 0,
        x: -200,
      },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        scrollTrigger: {
          trigger: ".category-fade-left",
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      ".category-fade-right",
      {
        opacity: 0,
        x: 200,
      },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        scrollTrigger: {
          trigger: ".category-fade-right",
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  return (
    <>
      <div className="flex flex-col w-full items-center my-10">
        <span className="cormorant-title-bold text-orange-500 category-fade-left">
          LİSTE
        </span>
        <div className="relative">
          <h1 className="max-md:text-4xl text-6xl font-bold cormorant category-fade-right">
            KATEGORİLER
          </h1>
          <div className="absolute -bottom-5 -right-6">
            <p className="quentin max-md:text-4xl text-6xl category-fade-left">lezzet</p>
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1 max-sm:px-10 max-md:px-16 md:px-24 lg:px-32 gap-5 ">
        <CategoryItem category={categories?.categories[0]} />
        <CategoryItem category={categories?.categories[1]} />
        <CategoryItem category={categories?.categories[2]} />
        <CategoryItem category={categories?.categories[3]} />
      </div>
    </>
  );
};

export default Categories;
