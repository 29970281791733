import React, { useEffect, useState } from "react";
import { FaHamburger, FaUserPlus } from "react-icons/fa";
import { FaArrowsLeftRightToLine } from "react-icons/fa6";
import { TbCategory } from "react-icons/tb";
import { BsFillBagPlusFill } from "react-icons/bs";
import { TbCategoryPlus } from "react-icons/tb";
import { FaUser } from "react-icons/fa";
import { BsQrCode } from "react-icons/bs";
import { GiExitDoor } from "react-icons/gi";
import { useLazyLogoutQuery } from "../../redux/api/authApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ setShowSidebar, showSidebar }) => {
  const [activeUrl, setActiveUrl] = useState("");
  const navigate = useNavigate();
  const [logout, {isSuccess}] = useLazyLogoutQuery();

  const sidebarItems = [
    {
      text: "Ürünler",
      url: "/admin/urunler",
      icon: <FaHamburger color="#64748b" size={28} />,
    },
    {
      text: "Ürün Ekle",
      url: "/admin/urun-ekle",
      icon: <BsFillBagPlusFill color="#64748b" size={28} />,
    },
    {
      text: "Kategoriler",
      url: "/admin/kategoriler",
      icon: <TbCategory color="#64748b" size={28} />,
    },
    {
      text: "Kategori Ekle",
      url: "/admin/kategori-ekle",
      icon: <TbCategoryPlus color="#64748b" size={28} />,
    },
    {
      text: "Kullanıcılar",
      url: "/admin/kullanicilar",
      icon: <FaUser color="#64748b" size={24} />,
    },
    {
      text: "Kullanıcı Ekle",
      url: "/admin/kullanici-ekle",
      icon: <FaUserPlus color="#64748b" size={32} />,
    },
    {
      text: "Qr",
      url: "/admin/qr",
      icon: <BsQrCode color="#64748b" size={32} />,
    },
  ];

  const logoutHandler = () => {
    logout();
  }

  useEffect(() => {
    setActiveUrl(window.location.pathname);

    if (isSuccess) {
      toast.success("Çıkış Yapıldı.")
      navigate("/mendels/fisekhane");
    };
  }, [activeUrl, isSuccess]);

  return (
    <div className="min-h-full flex flex-col justify-between">
      <div className="">
        <div className="flex justify-center border-b pb-5 mb-5">
          <img src="/images/logo.png" alt="logo" className="h-16 " />
        </div>
        {sidebarItems?.map((item) => (
          <div className={`flex items-center gap-4 ps-5 py-4 mx-3 rounded-md ${item.url === activeUrl && "bg-orange-100"} cursor-pointer`} onClick={() => window.location.href = `/mendels/fisekhane${item.url}`}>
            {item.icon}
            <span className="font-bold text-xl text-[#64748b]">
              {showSidebar && item.text}
            </span>
          </div>
        ))}
        <div className={`flex items-center gap-4 ps-5 py-4 mx-3 rounded-md bg-red-100 cursor-pointer`} onClick={logoutHandler}>
            <GiExitDoor  color="#64748b" size={32} />
            <span className="font-bold text-xl text-[#64748b]">
              {showSidebar && "Çıkış"}
            </span>
          </div>
      </div>
      <div
        className="flex flex-col w-full items-center justify-center border-t-2 py-2 cursor-pointer"
        onClick={() => setShowSidebar(showSidebar ? false : true)}
      >
        <FaArrowsLeftRightToLine color="orange" size={28} />
        <p>{showSidebar ? "KAPAT" : "AÇ"}</p>
      </div>
    </div>
  );
};

export default Sidebar;
